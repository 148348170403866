<script lang="ts" setup>
import type { NotificationMessage, NotificationMessagePayload } from "~/types";
const { t } = useT();

const props = defineProps<{
	unreadNotifications: NotificationMessage[];
	readNotifications: NotificationMessage[];
}>();
const emit = defineEmits<{
	(event: "closePopup"): void;
	(event: "setStatusRead", payload: number[]): void;
	(event: "updateNotificationStatus", payload: number[]): void;
	(event: "clickOnMessage", payload: NotificationMessagePayload): void;
}>();

const hasMessages = computed(() => props.unreadNotifications.length || props.readNotifications.length);

const handleClickMessage = (payload: NotificationMessagePayload) => {
	emit("clickOnMessage", payload);
};

onMounted(() => {
	dispatchGAEvent({
		event: "tooltips",
		type: props.unreadNotifications.length ? "new" : "empty",
		location: "header",
		form_name: "notifications"
	});

	if (!props.unreadNotifications.length) {
		return;
	}

	const unreadOfferIds = props.unreadNotifications.reduce<number[]>((acc, item) => {
		if (item.type === "offer" && new Date(item.dateEnd).getTime() < new Date().getTime()) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadOfferIds.length) {
		emit("setStatusRead", unreadOfferIds);
	}
});
onUnmounted(() => {
	if (!props.unreadNotifications.length) {
		return;
	}

	const unreadIds = props.unreadNotifications.reduce<number[]>((acc, item) => {
		if (!item.link) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadIds.length) {
		emit("updateNotificationStatus", unreadIds);
	}
});
</script>
<template>
	<APopper show>
		<template #content>
			<div class="notification-popup">
				<div class="header">
					<AText variant="taipei" class="text-cannes" :modifiers="['semibold']">
						{{ t("Inbox") }}
					</AText>
					<div>
						<NuxtIcon class="close-icon" name="20/close" filled @click.stop="$emit('closePopup')" />
					</div>
				</div>

				<div class="main-content" :class="{ 'main-content--empty': !hasMessages }">
					<template v-if="hasMessages">
						<div v-if="unreadNotifications.length" class="unread-wrapper">
							<div class="title-wrapper">
								<AText variant="tempe" class="title" :modifiers="['semibold']">
									{{ t("New") }}
									<span v-if="unreadNotifications.length" class="text-сirebon">{{ unreadNotifications.length }}</span>
								</AText>
							</div>

							<MNotificationCenterItem
								v-for="item in unreadNotifications"
								:key="`unread-${item.ncMessageId}`"
								:data="item"
								@click-message="handleClickMessage"
							/>
						</div>
						<div v-if="readNotifications.length" class="readed-wrapper text-cannes">
							<div class="title-wrapper">
								<AText variant="tempe" class="title" :modifiers="['semibold']">{{ t("Earlier") }}</AText>
							</div>

							<MNotificationCenterItem
								v-for="item in readNotifications"
								:key="`read-${item.ncMessageId}`"
								:data="item"
								@click-message="handleClickMessage"
							/>
						</div>
					</template>
					<template v-else>
						<NuxtImg src="/nuxt-img/notification-center/empty.png" width="80" height="80" format="webp" alt="empty" />

						<AText as="div" class="no-messages text-cannes" variant="taipei" :modifiers="['center', 'semibold']">
							{{ t("You don’t have any new notifications") }}
						</AText>
						<AText as="div" class="text-cannes" variant="toledo" :modifiers="['center']">
							{{ t("Messages older than 14 days are deleted.") }}
						</AText>
					</template>
				</div>
			</div>
		</template>
	</APopper>
</template>
<style scoped lang="scss">
.notification-popup {
	width: 370px;
	height: 100%;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 16px;
	cursor: auto;

	@include media-breakpoint-down(lg) {
		width: 100%;
		max-height: none;
	}

	&:deep(svg) {
		filter: none !important;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 0 24px;
		line-height: 24px;
	}

	.unread-count {
		margin-left: gutter(1);
	}

	.unread-wrapper {
		width: 100%;
	}

	.readed-wrapper {
		width: 100%;
		margin-top: gutter(2);
	}

	.close-icon {
		font-size: 24px;
		line-height: 1;
		margin-left: gutter(2);
		cursor: pointer;

		&:deep(svg) {
			margin-bottom: 0;
		}
	}

	.main-content {
		overflow: auto;
		width: 100%;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		align-items: center;

		&--empty {
			justify-content: center;
		}

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--сirebon);
			border-radius: 4px;
			width: 4px;
		}

		.title-wrapper {
			position: relative;
			margin-bottom: 10px;

			&::before {
				content: "";
				width: 100%;
				height: 1px;
				background: var(--chifeng);
				position: absolute;
				left: 0;
				top: 50%;
			}
		}

		.title {
			margin-bottom: 10px;
			position: relative;
			z-index: 2;
			background: var(--carabanchel);
			padding-right: 8px;
		}
	}

	.no-messages {
		padding: gutter(2);
		line-height: 1.35;
		p {
			margin-top: gutter(0.5);
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(1);
		padding: gutter(2) 0;

		&__support {
			white-space: nowrap;
			text-decoration: underline;

			&:hover {
				cursor: pointer;
				text-decoration: none;
			}
		}
	}
}
</style>
